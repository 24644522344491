<template>
  <v-row>
    <v-col
      v-for="(card, index) in cards"
      :key="index"
      sm="6"
    >
      <v-hover v-slot="{ hover }">
        <v-card
          rounded="xl"
          :elevation="hover ? '6' : ''"
          @click="goTo(card.cursus)"
        >
          <v-card-text>
            <v-img
              :src="require(`@/assets/${card.picture}.svg`)"
              height="200"
              contain
            ></v-img>
          </v-card-text>

          <v-card-title class="justify-center pt-0">{{ card.title }}</v-card-title>
        </v-card>
      </v-hover>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'AdminEnrCursus',

  props: {
    instanceId: String,
    instanceStatus: String,
    instanceType: String,
  },

  data: () => ({
    cards: [
      { cursus: 'logo', title: 'Logopédie', picture: 'undraw_typewriter_i8xd' },
      { cursus: 'kine', title: 'Kinésithérapie', picture: 'undraw_doctors_hwty' },
    ],
  }),

  methods: {
    goTo(department) {
      if (this.instanceStatus === 'En attente') return;
      this.$router.push(`/admin/enr/${this.instanceId}/cursus/${department}`);
    },
  },
};
</script>
